/**
 * Type: ページ
 * What: 89パーク-注意事項＆FAQページ
 */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { scroller } from 'react-scroll';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import BreadcrumbComponent from '../../../components/Breadcrumb';
import SimpleSwiper from '../../../components/Slider.fade';
import Image from '../../../util/Image';
import PostListComponent from '../../../components/PostList/PostList.obm';
import { Button } from '../../../components/Btn';

import ParkContents from '../../../config/89park';

const pageTitle = '89パーク';
const pageDescription = '王さんの野球への想いを体験型アトラクションに凝縮。打つ、投げる、捕るなど様々なチャレンジが楽しめます。';
const pageSlug = 'obm';
const pageLogo = 'logo-obm.png';

const sliderDataobj = [
  {
    filename: 'main-89top.jpg',
  },
];

// Data Props Template
const Template = () => {
  // State
  const [isModal, setIsModal] = useState(-1);
  const [modalCts, setModalCts] = useState({
    image: '',
    name: '',
    count: '',
    age: '',
    height: '',
    weight: '',
    comment: null,
  });

  // スクロール
  const scrollToTarget = (e, elem, offset) => {
    e.preventDefault();
    const $offset = offset || -110;
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };

  // モーダル展開
  const onClickModal = (e) => {
    e.preventDefault();
    const num = Number(e.currentTarget.dataset.modal.split('modal')[1]);
    setIsModal(num);
    setModalCts(ParkContents[num]);
  };

  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(-1);
  };

  // モーダルコンテンツ
  const ModalContent = () => (
    <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="modal-background" onClick={onCloseModal} />
      <button
        type="button"
        className="delete"
        aria-label="close"
        data-sal="zoom-in"
        data-sal-delay="300"
        data-sal-easing="ease-out-expo"
        onClick={onCloseModal}
      />
      <div className="modal-content">
        <div className="e-modal-image">
          <figure className="image">
            <Image filename={modalCts.image} />
          </figure>
        </div>
        <div className="e-modal-content">
          <h3 className="ttl c-4">{modalCts.name}</h3>
          <h4>注意事項</h4>
          <ul>
            <li>
              体験人数:
              <b>{modalCts.count}</b>
            </li>
            <li>
              対象年齢:
              <b>{modalCts.age}</b>
            </li>
            <li>
              身長制限:
              <b>{modalCts.height}</b>
            </li>
            <li>
              体重制限:
              <b>{modalCts.weight}</b>
            </li>
          </ul>
          {modalCts.comment && (
            <p>
              {modalCts.comment}
            </p>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <Layout pageSlug={pageSlug} bg="bg-4">
      <SEO
        title={pageTitle}
        description={pageDescription}
      />
      <BreadcrumbComponent
        hierarchy={3}
        bread2url="obm/"
        bread2title="ヒストリーゾーン・89パーク・STUDIO E・ZO"
        bread3title={pageTitle}
      />
      <section className="pageMain bg-12 bs-4 no-border">
        <div className="kv-wrap bg-4">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-4">
                  <span>4</span>
                  <span>F</span>
                </div>
                <h3 className="ttl">
                  <span className="logo-under">
                    <Image src={pageLogo} alt={pageTitle} />
                    {/* <span>
                      <b className="is-1">{pageTitle}</b>
                    </span> */}
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container bg-4 pb-4">
          <div
            className="inner-slim bg-grey"
            data-sal="slide-up"
            data-sal-easing="ease-out-expo"
          >
            <div className="table-base park-ticket new-table">
              <p className="new">NEW</p>
              <table>
                <tbody>
                  <tr>
                    <th>89パークチケット</th>
                    <td>
                      <p className='fwb'>16歳以上</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,200円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>1,000円</b>
                        </dd>
                      </dl>
                      <p className='fwb mt30'>15歳以下</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>600円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>500円</b>
                        </dd>
                      </dl>
                      <p className="mt30">
                        ※ヒストリーゾーンへはご入場いただけません
                      </p>
                      <p>各日程のレギュラー・ピークは空席情報カレンダーでご確認ください。</p>
                      <p><a href="/ticket/" className="tx-link">空席情報カレンダーはこちら</a></p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className='list-mark'>
                ※6歳以下は無料、保護者はチケットの購入が必要です。
              </p>
              <p className='list-mark fwb attention'>
                ※お子様(12歳以下)のご入場には、保護者の同伴が必要です。保護者1名に対しお子様3名までご入場いただけます。保護者もチケット購入が必要です。
              </p>
            </div>
            {/* {url.ticketReady === '1' ? ( */}
              <div className="btn-wrap mb-0">
                <Button
                  // href={`${url.ticket}#/order?lang=ja&id=${url.uuidObm}`}
                  href="https://ticket.e-zofukuoka.com/#/"
                  target="_blank"
                  className="btn-buy"
                  rel="noopener noreferrer"
                >
                  チケット購入
                </Button>
              </div>
            {/* ) : null} */}
          </div>

        </div>
        <div className="section-link">
          <ul>
            {/*
            <li>
              <a
                href="#topics"
                onClick={(e) => scrollToTarget(e, 'topics', -68)}
              >
                <span
                  className="arrow"
                >
                  TOPICS
                </span>
              </a>
            </li>
            */}
            <li>
              <a
                href="#attraction"
                onClick={(e) => scrollToTarget(e, 'attraction')}
              >
                <span
                  className="arrow"
                >
                  アトラクション
                </span>
              </a>
            </li>
            {/*
            <li>
              <a
                href="#notes"
                onClick={(e) => scrollToTarget(e, 'notes')}
              >
                <span
                  className="arrow"
                >
                  注意事項
                </span>
              </a>
            </li>
            */}
            {/*
            <li>
              <Link
                to="/inquiry/group/"
              >
                <span
                  className="arrow"
                >
                  団体予約
                </span>
              </Link>
            </li>
            */}
            {/* <li>
              <Link
                to="/ticket/"
              >
                <span
                  className="arrow"
                >
                  チケット購入
                </span>
              </Link>
            </li> */}
          </ul>
        </div>
      </section>
      <PostListComponent />
      <section className="section sc-museum bg-12" id="attraction">
        <div className="container">
          <div className="inner">
            <h3 className="headline">
              <span>アトラクション</span>
            </h3>
            <p className="text1 mb40">
              王さんの野球への想いを体験型アトラクションに凝縮。打つ、投げる、捕るなど様々なチャレンジが楽しめます。
            </p>
            <div className="sc-columns">

              {
                ParkContents.map((val, index) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div className="col col-4 col-spacer floorbc4 obm-attention">
                    <a
                      href="#"
                      data-modal={`modal${index}`}
                      data-sal="slide-up"
                      data-sal-easing="ease-out-expo"
                      onClick={(e) => onClickModal(e)}
                    >
                      <div className="col-inner">
                        <div className="col-image">
                          <figure className="image">
                            <Image filename={val.image} />
                          </figure>
                        </div>
                        <div className="col-content">
                          <h3 className="ttl c-4">{val.name}</h3>
                          <p className="text">
                            {val.text}
                          </p>
                          <div className="btn-group">
                            <p className="btn-attention">
                              <span>注意事項を見る</span>
                              <small />
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        {/*
        <div className="obm-acd">
          <Accordion
            Name="注意事項はこちら"
            Title="注意事項"
            Close="閉じる"
            Icon={(
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="25" viewBox="0 0 11 25">
                <path d="M1010,5336.89v3.94a0.42,0.42,0,0,0,.69.36l4.55-3.95a0.387,0.387,0,0,1,.53,0l4.54,3.94a0.426,0.426,0,0,0,.69-0.37v-3.94a0.468,0.468,0,0,0-.16-0.36l-5.07-4.41a0.409,0.409,0,0,0-.53,0l-5.08,4.42a0.49,0.49,0,0,0-.16.37m0,7.85v3.94a0.426,0.426,0,0,0,.69.37l4.55-3.96a0.387,0.387,0,0,1,.53,0l4.54,3.94a0.422,0.422,0,0,0,.69-0.36v-3.95a0.484,0.484,0,0,0-.16-0.36l-5.07-4.4a0.387,0.387,0,0,0-.53,0l-5.08,4.41a0.506,0.506,0,0,0-.16.37m0,7.85v3.94a0.426,0.426,0,0,0,.69.37l4.55-3.96a0.409,0.409,0,0,1,.53,0l4.54,3.95a0.426,0.426,0,0,0,.69-0.37v-3.94a0.49,0.49,0,0,0-.16-0.37l-5.07-4.4a0.387,0.387,0,0,0-.53,0l-5.08,4.41a0.506,0.506,0,0,0-.16.37" transform="translate(-1010 -5332)" />
              </svg>
            )}
          >
            <h3>段落タイトル段落タイトル段落タイトル</h3>
            <div className="content">
              <p>
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
              </p>
            </div>
            <h3>段落タイトル段落タイトル段落タイトル</h3>
            <div className="content">
              <p>
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
              </p>
              <p>
                段落飛ぶ場合は上下20px開けてください。段落飛ぶ場合は上下20px開けてください。段落飛ぶ場合は
                上下20px開けてください。段落飛ぶ場合は上下20px開けてください。段落飛ぶ場合は上下20px開けて
                ください。段落飛ぶ場合は上下20px開けてください。
              </p>

            </div>
            <h3>段落タイトル段落タイトル段落タイトル</h3>
            <div className="content">
              <p>
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
              </p>
            </div>
            <h3>段落タイトル段落タイトル段落タイトル</h3>
            <div className="content">
              <p>
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
              </p>
              <p>
                段落飛ぶ場合は上下20px開けてください。段落飛ぶ場合は上下20px開けてください。段落飛ぶ場合は
                上下20px開けてください。段落飛ぶ場合は上下20px開けてください。段落飛ぶ場合は上下20px開けて
                ください。段落飛ぶ場合は上下20px開けてください。
              </p>

            </div>
          </Accordion>
        </div>
        */}
      </section>
      <ModalContent />
    </Layout>
  );
};

export default Template;
