const ParkContents = [
  {
    image: 'atraction-01.jpg',
    name: 'ボールプール',
    text: '緑や白のカラフルなボールを壁の穴に向けて投げてみよう！※現在閉鎖中',
    count: '2～6人',
    age: '3歳~6歳',
    height: 'なし',
    weight: 'なし',
    comment: '※現在閉鎖中',
  },
  {
    image: 'atraction-02.jpg',
    name: 'ホークスDASH',
    text: 'ホークスの選手やキャラクターの映像を選び、短距離で走って競争。',
    count: '1人',
    age: '3歳以上（6歳以下は保護者同伴1名）',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-03.jpg',
    name: 'フィジカルチャレンジ',
    text: 'いろいろな種目で自分の体力を測ってみよう！',
    count: '各種目1名',
    age: '3歳以上（6歳以下は保護者同伴）',
    height: 'なし',
    weight: 'なし ※懸垂のみ120kgまで',
    comment: null,
  },
  {
    image: 'atraction-04.jpg',
    name: 'なわとびジャンパー',
    text: '誰もが遊んだことのある”なわとび”をデジタルの力でさらに楽しく進化させ跳んだ回数を競うアトラクション。',
    count: '1人',
    age: '5歳以上',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-05.jpg',
    name: 'ストライクアウトチャレンジ',
    text: '画面に出てくる16枚に分かれた的にボールを投げ10球で何枚あてられるかチャレンジ。',
    count: '1人',
    age: '6歳以上（10歳以下は保護者同伴）',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-06.jpg',
    name: 'バッティングコンタクトゲーム',
    text: 'バットで的をねらってボールを打ってみよう！',
    count: '1人',
    age: '9歳以下（6歳以下は保護者同伴）',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-07.jpg',
    name: 'リードチャレンジ',
    text: 'ピッチャーが投げるボールが1塁に届くまでに急いでベースに戻ろう。',
    count: '1人',
    age: '3歳以上（6歳以下は保護者同伴）',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-08.jpg',
    name: 'プロレベルピッチング',
    text: 'プロ野球選手が投げるボールの速さを間近で体験。王さんが756号を打った速さも体験できます。',
    count: 'なし',
    age: 'なし（6歳以下は保護者同伴）',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-09.jpg',
    name: 'ホークスティーバッティング',
    text: 'ティーの上にのせたボールを大きなモニターに向かって打つと距離が表示。球場にいるかの様な映像も楽しめる！',
    count: '1人',
    age: '10歳以上',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-10.jpg',
    name: 'ホークストスバッティング',
    text: '前から飛んできたボールを大きなモニターに向かって打つと距離が表示。球場にいるかの様な映像も楽しめる！',
    count: '1人',
    age: '10歳以上',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-11.jpg',
    name: 'キャッチ＆スロー',
    text: '守備練習のアトラクションです。画面下から出てくるボールをキャッチし、指示された内野手へ素早く正確に送球して得点を競います。',
    count: '1人',
    age: '6歳以上（10歳は以下は保護者同伴）',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-12.jpg',
    name: 'ピッチングスピード',
    text: 'スピードガンで自分の投げたボールの速さを測ってみよう！',
    count: '1～2人',
    age: 'なし（6歳以下は保護者同伴）',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
  {
    image: 'atraction-13.jpg',
    name: 'キャッチボールエリア',
    text: 'キャッチボールをしてみよう！館内でグローブの貸し出しもしています。',
    count: '2人',
    age: 'なし（6歳以下は保護者同伴）',
    height: 'なし',
    weight: 'なし',
    comment: null,
  },
];
export default ParkContents;
